* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

.Header_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid rgb(47, 255, 28);
  margin: 2% 10%;
}

.modal-content {
  max-height: calc(100vh); /* Adjust the value according to your layout */
  overflow-y: auto;
}
.modal-content::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
  margin-left: 1px;
  background-color: rgb(56, 95, 17);
}
.modal-content::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: rgb(95, 255, 32);
  border-radius: 20px;
}
.scrollbar::-webkit-scrollbar {
  background-color: lightblue;
  border-radius: 20px;
  width: 10px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 255, 0, 0.616);
  border-radius: 20px;
  width: 10px;
}

/* clg logo  */

.Logo {
  height: 150px;
  width: 150px;
}

.Logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.text {
  text-transform: capitalize;
  font-size: 1.5rem;
}
.heading {
  font-weight: 800;
}
.clg {
  font-weight: 500;
}

.body {
  border: 2px solid red;
  width: 100vw;
}

.alert_box {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 20px;
  left: 0;
  width: 100%;
  z-index: 9999;
}
.alert {
  width: 30%;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .alert {
    width: 90%;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .alert {
    width: 60%;
  }
}

.uploadfileform {
  position: absolute;
  border: 2px solid red;
  z-index: -1;
  margin: 0;
  padding: 0;
  width: 35%;
  height: 35%;
  opacity: 0.5;
}

@keyframes wave {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.25em);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-wave {
  animation: wave 1s infinite;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100vh;
  font-family: "Source Sans Pro", sans-serif;
}

.sideFix {
  width: 20%;
  height: 100vh;
  overflow-x: scroll;
}

.sideFix::-webkit-scrollbar {
  display: none;
}

.postfix {
  overflow-x: scroll;
}
.postfix::-webkit-scrollbar {
  display: none;
}

.content {
  width: 80%;
  overflow-x: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Userpost {
  height: 70vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Userpost::-webkit-scrollbar {
  display: none;
}
.content::-webkit-scrollbar {
  display: none;
}
.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #a3a3a398;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(114, 114, 114, 0.76);
  border: 2px solid rgba(73, 73, 73, 0.486);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #09aa03;
}

.LinkNav {
  /* font-weight: 200; */
  /* font-size: large; */
}
.sub {
  padding-bottom: 5px;
  border-bottom: #888 1px solid;
}

/* notification */

.red-dot {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(162, 255, 100);
  color: white;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  padding: 1px;
  font-weight: 900;
  font-size: 13px;
}
.user_not {
  position: absolute;
  width: 45px;
}
.green-dot {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(162, 255, 100);
  color: white;
  border-radius: 50px;
  height: 15px;
  width: 15px;
}

/*  msg box  */
.msgBox {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.msgBox::-webkit-scrollbar {
  display: none;
}

/*  loader  */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.loader {
  border: 4px solid transparent; /* Transparent border */
  border-top: 4px solid #ff5722; /* Reddish-Orange border for animation */
  border-right: 4px solid #e91e63; /* Pink border for animation */
  border-bottom: 4px solid #9c27b0; /* Purple border for animation */
  border-left: 4px solid #03a9f4; /* green border for animation */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite; /* Rotate the loader */
}

@keyframes spin {
  0%,
  25% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Add a loading text */
.loader-text {
  margin-top: 20px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.contribution-card {
  transition: all 0.3s ease-in-out;
}

.contribution-card:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contribute-button {
  background-color: #4a90e2;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.contribute-button:hover {
  background-color: #357abd;
}

.content-top-nav {
  background: #ffffff;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
}
